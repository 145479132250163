<template>
  <b-modal
    id="quote-view-modal"
    title="Quote"
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <div class="container" style="margin-bottom: 30px">
      <div style="margin-bottom: 30px">
        <b-row>
          <b-col md="8" v-if="quote">
            <div class="">
              <span>
                <h5>
                  <span style="font-weight: bold">Name:</span>
                  {{ quote.full_name }}
                </h5>
              </span>
              <span>
                <h5>
                  <span style="font-weight: bold">Email:</span>
                  {{ quote.email }}
                </h5>

                <h5>
                  <span style="font-weight: bold">Mobile:</span>
                  {{ quote.mobile }}
                </h5>
              </span>

              <h5 style="font-weight: bold">
                Message:
                <span class="font-weight-normal" v-if="quote">
                  {{ quote.message }}
                </span>
              </h5>
            </div>
          </b-col>
          <b-col md="4" v-if="quote && quote.referral_company_name != null">
            <div>
              <h3 style="font-weight: bolder">Referred BY: </h3>
              <h5 style="font-weight: bolder">Name: <span style="font-weight:normal">{{
                  quote.referral_company_name
                }}</span></h5>
              <h5 style="font-weight: bolder">
                Reference Number:  <span style="font-weight:normal">{{
                  quote.referral_company_code
                }}</span>
              </h5>
              <h5 style="font-weight: bolder">
                Company Name:
                <span class="font-weight-normal">{{
                  quote.referral_company_company_name
                }}</span>
              </h5>
            </div>
          </b-col>
          <b-col md="4" v-if="quote && quote.referral_company_name === null">
            <div>
              <h4 style="font-weight: bolder">
                Not Referred By Any Referrel Company.
              </h4>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import util from "@/util.js";

export default {
  props: {
    showModal: Boolean,
    quote: Object,
  },

  data() {
    return {
      // procedures : [],
      // fieldsProcedures: [
      // { key: "name", label: "Name" },
      //   { key: "amount",label: "Starting From"},
      //   { key: "manage", label: "" },
      // ],
    };
  },
  mixins: [util],

  methods: {
    ...mapActions({}),
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  height: 100%;
}
.flex-container span {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
}
</style>
